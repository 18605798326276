<template>
  <div>
    <el-card class="box-card">
      <div slot="header">
        <div>填写作品信息</div>
      </div>
      <el-form class="worksForm" :rules="formDataRules" :model="formData" ref="worksForm" label-width="120px">
        <el-form-item class="small" label="作品名称" prop="worksname">
          <el-input v-model="formData.worksname" maxlength="40">
            <span slot="append">还可输入<span
              class="sctp-red">{{ 40 - (formData.worksname && formData.worksname.length || 0) }}</span>字</span>
          </el-input>
        </el-form-item>
        <el-form-item label="上传应用">
          <el-upload
            :http-request="fileUploadFunction"
            action=""
            :limit="1"
            :data="{...uploadEnum.JOB}"
            :on-exceed="fileCountOver"
            :file-list="fileList"
            :before-remove="beforeRemove"
            :before-upload="beforeUpload"
            :on-success="onUploadSuccess.bind(null, {key: 'apkurl'})"
            :on-remove="handleRemove.bind(null, {key: 'apkurl'})">
            <el-button size="small" icon="el-icon-link">上传apk/ipa 文件</el-button>
            <div class="el-upload__tip" slot="tip">
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="作品信息">
          <ckeditor v-model="formData.details"></ckeditor>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="onSubmitClick"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import uploadEnum from "@/resources/js/uploadenum";
const config = require('../../../resources/js/config');
export default {
  name: 'worksEdit',
  props: ['id'],
  data() {
    return {
      uploadEnum,
      fileList: [],
      formData: {
        worksname: null,
        id: null,
        details: null,
        apkurl: null,
        userid: null
      },
      formDataRules: {
        worksname: [
          {required: true, message: '请输入作品名称', trigger: 'change'},
          {
            validator: (rule, value, callback) => {
              if (!value){
                callback(new Error('请输入作品名称'))
              } else if (!/^\S+$/.test(value)) {
                callback(new Error('作品名称不能输入空格'))
              } else {
                callback()
              }
            }
          }
        ],
        details: [
          {required: true, message: '请输入作品详情', trigger: 'change'}
        ]
      }
    }
  },
  components: {
    ckeditor: () => import('@CMP/ckeditor/Index'),
  },
  methods: {
    beforeAvatarUpload(file) {
      const index = file.name.lastIndexOf('.');
      const suffix = file.name.substr(index + 1);
      console.log(suffix);
      if (suffix === 'apk' || suffix === 'ipa') {

      } else {
        this.$message({message: '仅支持上传apk 或者 ipa文件格式，请重新选择文件', type: 'error'});
        return false
      }
    },
    onSubmitClick() {
      this.$refs.worksForm.validate(valid => {
        if (valid) {
          this.doSubmitForm()
        } else {
          this.$message.warning('请检查表单');
          return false
        }
      })
    },
    doSubmitForm() {
      this.$request.post({
        reqcode: '1126',
        reqdesc: '发布兼职作品',
        userid: this.user.userId,
        id: this.id,
        worksname: this.formData.worksname,
        details: this.formData.details,
        apkurl: this.formData.apkurl
      }).then(res => {
        this.$message.success('发布成功');
        this.goPage('/user/works')
      })
    },
    handleRemove({key}, file, fileList) {
      this.formData.apkurl = this.formData.apkurl.replace(',' + file.url, '').replace('file.url', '');
      console.log(this.formData.apkurl)
    },
    beforeRemove(file, fileList) {
      return true;
    },
    beforeUpload(file) {
      const allowType = [
        'application/vnd.android',
      ];
      const suffix = [
        'apk', 'ipa'
      ];
      const isMatchType =
        allowType.some(type => file.type.startsWith(type))
        || suffix.some(item => file.name.endsWith(item))
      ;
      const isLtSize = file.size / 1024 / 1024 < 50;
      if (!isMatchType) {
        this.$message.error('上传文件只能是 apk/ipa 格式!');
        return false;
      }
      if (!isLtSize) {
        this.$message.error('上传文件大小不能超过 50MB!');
        return false;
      }
      return isMatchType && isLtSize && this.$message('文件正在上传') && true
    },
    onUploadSuccess({key}, res, file, fileList) {
      //  this.formData[key] = fileList;
      if (res.code) {
        const {size, src} = res;
        if (this.formData.apkurl != null) {
          this.formData.apkurl += ',' + src
        } else {
          this.formData.apkurl = src
        }
        this.$message.success('上传成功')
      } else {
        this.$message.error('上传文件失败')
      }
    },
    fileCountOver() {
      this.$message.warning('文件数量已达最大限制')
    },
    queryList() {
      if (!this.id) {
        return
      }
      this.$request.post({
        reqcode: '1127',
        reqdesc: '获取作品信息',
        id: this.id
      }).then(res => {
        // const { data } = res
        this.formData = res.data[0];
        this.fileList = this.formData.apkurl ? this.formData.apkurl.split(',').map(item => {
          return {
            url: item,
            name: item
          }
        }) : [];
        console.log(this.fileList)
      })
    }
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.queryList()
  }
}
</script>

<style scoped>
/*@formatter:off*/
  .inline-form/deep/ .el-input {width: 180px;}
  .worksForm/deep/ .el-form-item.small .el-input {width: 50%!important;}
  /*@formatter:on*/
</style>
